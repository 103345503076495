




























































import {Component, Prop, Provide, Vue} from 'vue-property-decorator'
import {Exchange} from '@/model/resource/Exchange'
import {InputExchangeSchema} from '@/schema/resource/Exchange/InputExchangeSchema'

@Component
export default class PersistExchangeView extends Vue {
  @Prop() id?: string

  @Provide('validator') validator = this.$validator

  schema = new InputExchangeSchema()
  exchange = new Exchange()

  async created() {
    this.populateResource()
    await this.populate()
  }

  populateResource() {
    this.schema.collectionBlockchain.queryAsPage()
  }

  async populate() {
    const id = Number(this.id) || null

    if (id) {
      await this.exchange.getExchange(id)
    }

    this.$await.done('getExchange')
  }

  async persist() {
    const isValid = await this.validator.validateAll()

    if (!isValid) {
      this.$toast.abort('system.error.validation')
    }

    await this.exchange.persistExchange()
    this.$toast.success('system.success.persist')
    await this.$nav.push('/exchange/list')
  }
}
